import React from 'react'
import { NavLink } from 'react-router-dom'

const Notfoundpage = () => {
    return (
        <div>
            <div className="container-fluid display">
                <div className="row">
                    <div className="col-lg-6">

                        <h1 className=''>404* (Page Not Found)</h1>
                        <div className="display__img">
                            <img src={require("../../Components/Images/Scarecrow.png")} alt="404-Scarecrow" className='w-50 pt-3' />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="page_not_found">
                            <h2 className="">I have bad news for you</h2>
                            <p className="">
                                The page you are looking for might be removed or is temporarily unavailable
                            </p>
                            <div className='mt-5'>
                                <NavLink to="/" className="mybtn p-3 ">Back to homepage</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default Notfoundpage
