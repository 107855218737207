import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config/config'; // adjust path if needed

const Topcourses = () => {
    const [navcourse, setNavcourse] = useState([]);

    useEffect(() => {
        const fetchNavCourse = async () => {
            try {
                const response = await axios.get(
                    `${config.API_BASE_URL}/api/admin/get_allNavCourse`
                );
                const fetchedData = response.data.allnavCourse || [];
                setNavcourse(fetchedData);
                console.log("Fetched Data:", fetchedData); // Debugging log
            } catch (error) {
                console.error("Error fetching course data:", error);
            }
        };

        fetchNavCourse();
    }, []);

    return (
        <div className="container-fluid Admission_cont">
            <div className="container">
                <h1 className="exploretitle ">
                    Top Courses 2025
                </h1>
                <div className="row justify-content-center">
                    {navcourse.length > 0 ? (
                        navcourse.map((course, index) => (
                            <div key={index} className="col-md-6 col-lg-3 d-flex justify-content-center">
                                <div className="MyCard" style={{ width: '15rem', position: 'relative' }}>
                                    <p className="card-title fw-bold">{course.pageName}</p>
                                    <p className="small-desc text-secondary">
                                        Explore detailed information and top colleges offering {course.pageName}.
                                    </p>
                                    <a href={`/Collegespage/${course._id}`} className="stretched-link"></a>
                                    <div className="go-corner" style={{ position: 'absolute', top: 0, right: 0 }}>
                                        <div className="go-arrow">→</div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center">Loading courses...</p>
                    )}
                </div>
            </div>
        </div>


    );
};

export default Topcourses;
