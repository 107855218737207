import React from 'react'
// import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import Popupform from '../../Components/Popupform/Popupform'
import axios from 'axios';
import config from '../../config/config';

const Coursemainpage = () => {

  const [tableData, setTableData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchSingleCollegeData = async () => {
      try {
        const response = await axios.get(
          `${config.API_BASE_URL}/api/admin/college_page_course_details/${id}`
        );
        const fetchedData = response.data.pageCourseDetails || [];
        setPageData(fetchedData);
        setTableData(fetchedData.coursesDeatils);
        console.log("fetchedData", fetchedData); // Log for debugging
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    fetchSingleCollegeData();
  }, []);


  const [showPopup, setShowPopup] = useState(false);

  const handleOpenPopup = (e) => {
    e.preventDefault(); // Prevent navigation
    setShowPopup(true);
    document.body.style.overflow = "hidden"; // Disable background scrolling
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    document.body.style.overflow = "auto"; // Enable background scrolling
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (showPopup) {
      document.body.classList.add("popup-open");
    } else {
      document.body.classList.remove("popup-open");
    }
  }, [showPopup]);


  // const navigate = useNavigate();


  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col p-0 m-0">
            <div className='background_banner cousepage_banner'>
              <h2>{pageData.bannerText}</h2>
              <p>{pageData.bannersubText}</p>
            </div>
          </div>
        </div>
      </div>

      {/* <h4>College Details for ID: {id}</h4> */}



      {/* about us section  */}
      <div className="container mt-4">
        <div className="row">
          <div className="col">
            <div className=''>
              <h2 className='exploretitle '>{pageData?.heading}</h2>
              <p className='explore_para text-start'>{pageData?.subHeading1}</p>
              <p className='explore_para text-start'>{pageData?.subHeading2}</p>
              {/* <p className='explore_para text-start'>At the undergraduate level, Indian Institute of Technology Bombay offers B.Tech, B.Tech + M.Tech, B.Sc & B.Des whereas at the postgraduate level, it offers M.Tech, M.Tech + PhD, M.Des, MBA, MA + PhD, M.Sc + PhD etc. It also offers PhD in a variety of disciplines. In total, it offers 80+ courses via its 17 departments. IIT Bombay B.Tech is one of the most preferred course offered at the institute. Aspirants must appear for JEE Advanced and JoSAA counselling for admission to its B.Tech program. B.Tech tuition fee at IIT Bombay is INR 1,00,000 per semester for Indian nationals & INR 3,00,000 per semester for Foreign nationals. For admission to its PG courses, the institute accepts GATE, CEED, IIT JAM, CAT Scores. Further, it accepts MET (M.Phil. Entrance Test) for M.Phil. programme. The institute also offers innovative short-term courses, continuing education and distance learning.</p> */}
            </div>
          </div>
        </div>
      </div>



      {/* table section  */}
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <div className="table-responsive">
              <table className="table table-sm table-bordered">
                <thead>
                  <tr style={{ backgroundColor: '#e5edff' }} className="">
                    <th scope="col">Course</th>
                    <th scope="col">Fees</th>
                    <th scope="col">Eligibility</th>
                    <th scope="col">Application Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className='tablebody'>
                  {tableData.map((item, index) => (
                    <tr key={index}>
                      <td className='px-3 fw-semibold'>
                        <NavLink
                          to={`/branch/${pageData?._id}/${item._id}`}
                          className="text-decoration-none text-dark"
                        >
                          {item.courses}
                        </NavLink>
                      </td>
                      <td>
                        <div>
                          <p> &#8377; {item.fees}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h6>{item.eligibility}</h6>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h6>{item.applicationDate}</h6>
                        </div>
                      </td>
                      <td>
                        <div>
                          <NavLink to="" className="mt-3 text-decoration-none" onClick={handleOpenPopup}>
                            Apply Now <i className="fa fa-angle-double-right"></i>
                          </NavLink>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>
            </div>

            {/* Popup Modal */}
            {showPopup && (
              <div className="popup-overlay">
                <div className="popup-content">
                  <button className="close-btn" onClick={handleClosePopup}>
                    &times;
                  </button>
                  <Popupform />
                </div>
              </div>
            )}

          </div>
        </div>
      </div>




      {/* Academic content section  */}
      <div className="container mt-4">
        <div className="row">
          <div className="col">
            <div className=''>
              <h2 className='exploretitle '>Academic Excellence</h2>
              <p className='explore_para text-start'>India Today is one of the leading and well-reputed English news magazines in India published by Living Media India Limited every week. The Marketing & Development Research Associates (MDRA), a marketing and research firm from Delhi and India Today, conducts a college ranking survey. It ranked Indian colleges in fields like Science, Arts, Commerce, Management, Engineering, etc.</p>
              <p className='explore_para text-start'>Ranked 3rd in the Engineering category by NIRF 2023 and 149th globally by QS World University Rankings 2024, IIT Bombay offers a wide range of undergraduate, postgraduate, and doctoral programs. The institute has 17 departments and provides more than 80 courses, including:</p>


              <ul className='explore_para text-start'>
                <li><strong>Undergraduate Programs:</strong> B.Tech, B.Sc, B.Des, Dual Degree (B.Tech + M.Tech)</li>
                <li><strong>Postgraduate Programs:</strong> M.Tech, M.Des, MBA, M.Sc, MA + PhD, M.Tech + PhD</li>
                <li><strong>Doctoral Programs:</strong> PhD across various disciplines</li>
                <li> <strong>Specialized Courses:</strong>Specialized Courses: Short-term, continuing education, and distance learning programs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      {/* cards images section  */}

    </div>
  )
}

export default Coursemainpage
